import { Component, Input, OnInit } from '@angular/core';
import {
  MixedLoyaltyItemType,
  ProcessProviderId,
  ScorecardService,
  RewardsWalletItem,
  RewardsWalletQuery,
} from '@fgb/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'fgb-wallet-page',
  templateUrl: './wallet-page.component.html',
  styleUrls: ['./wallet-page.component.scss'],
})
export class WalletPageComponent implements OnInit {
  @Input() walletItems: RewardsWalletItem[];
  @Input() marketplaceFilterTypes: ProcessProviderId[] = [0, 1, 2, 3, 4, 5];
  @Input() itemTypes: MixedLoyaltyItemType[] = [MixedLoyaltyItemType.All];
  isList: boolean = true;
  walletItemsUnclaimed$: Observable<RewardsWalletItem[]>;

  constructor(
    private scorecardService: ScorecardService,
    private rewardWalletQuery: RewardsWalletQuery
  ) {}

  ngOnInit() {
    this.walletItemsUnclaimed$ = this.rewardWalletQuery.selectRewardWalletItemsByStatus(false);
    this.scorecardService.fetchScorecards().toPromise();
  }
}
