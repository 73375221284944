<nav class="nav nav-secondary account-details-nav">
  <a
    class="nav-item"
    [routerLink]="['/account']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    attr.aria-label="{{ 'account.nav.account.screenreader' | translate: { fgbdefault: 'Move to Account page' } }}"
  >
    <div class="icon material-icons" aria-hidden="true">person</div>
    <label>{{ 'account.nav.profile' | translate }}</label>
  </a>
  <a
    class="nav-item"
    [routerLink]="['/activity']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    attr.aria-label="{{ 'account.nav.activity.screenreader' | translate: { fgbdefault: 'Move to Activity page' } }}"
  >
    <div class="icon material-icons" aria-hidden="true">history</div>
    <label>{{ 'account.nav.activity' | translate }}</label>
  </a>
  <a
    class="nav-item"
    [routerLink]="['/partners']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    attr.aria-label="{{ 'account.nav.partners.screenreader' | translate: { fgbdefault: 'Move to partners page' } }}"
  >
    <div class="icon material-icons" aria-hidden="true">people</div>
    <label>{{ 'account.nav.partners' | translate }}</label>
  </a>
  <a
    class="nav-item"
    [routerLink]="['/pointsmanagement']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    attr.aria-label="{{ 'account.nav.pointsmanagement.screenreader' | translate: { fgbdefault: 'Move to Point Management page' } }}"
  >
    <div class="icon material-icons" aria-hidden="true">emoji_events</div>
    <label>{{ 'account.nav.points.management' | translate }}</label>
  </a>
  <a
    class="nav-item"
    [routerLink]="['/subscriptions']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    attr.aria-label="{{ 'account.nav.subscriptions.screenreader' | translate: { fgbdefault: 'Move to Subscriptions page' } }}"
  >
    <div class="icon material-icons" aria-hidden="true">auto_awesome</div>
    <label>{{ 'account.nav.subscriptions' | translate }}</label>
  </a>
  <a
    class="nav-item"
    [routerLink]="['/notificationmanagement']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    attr.aria-label="{{ 'account.nav.notificationmanagement.screenreader' | translate: { fgbdefault: 'Move to Notification Management page' } }}"
  >
    <div class="icon material-icons" aria-hidden="true">notifications</div>
    <label>{{ 'account.nav.management' | translate }}</label>
  </a>
</nav>
