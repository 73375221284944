<div class="points-summary-bar d-flex justify-content-between d-lg-none my-2">
  <!-- Back Button -->
  <ng-container *notInProductCode="productcode.SingleGameBuyer">
    <div class="text-white cursor-pointer d-flex align-items-center d-lg-none" [routerLink]="[backButtonLink]">
      <span class="material-icons text-white pe-2">keyboard_arrow_left</span>
      <span *ngIf="marketplaceItemGroup == 'Marketplace'" class="back-to-text">{{ 'back.to.marketplace' | translate }}</span>
      <span *ngIf="marketplaceItemGroup == 'Events'" class="back-to-text">{{ 'back.to.events' | translate }}</span>
      <span *ngIf="!marketplaceItemGroup" class="back-to-text">{{ 'back.to.marketplace' | translate }}</span>
    </div>
  </ng-container>

  <ng-container *inProductCode="productcode.SingleGameBuyer">
    <div class="text-white cursor-pointer d-flex align-items-center d-lg-none" [routerLink]="['/rewards']">
      <span class="material-icons text-white pe-2">keyboard_arrow_left</span>
      <span *ngIf="marketplaceItemGroup == 'Marketplace'" class="back-to-text">{{ 'back.to.marketplace' | translate }}</span>
      <span *ngIf="marketplaceItemGroup == 'Events'" class="back-to-text">{{ 'back.to.events' | translate }}</span>
    </div>
  </ng-container>
</div>
