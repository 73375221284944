import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ActionLabels, MarketplaceExtendedQuery, MarketplaceItemExtended, MarketplaceService } from '@fgb/core';
import { Observable } from 'rxjs';
import { tap, pluck, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { ProductCode } from 'src/app/shared/utilities/product-code';
@Component({
  selector: 'fgb-marketplace-detail-page',
  templateUrl: './marketplace-detail-page.component.html',
  styleUrls: ['./marketplace-detail-page.component.scss'],
})
export class MarketplaceDetailPageComponent implements OnInit {
  id: number;
  actionLabels = ActionLabels;
  marketplaceItem$: Observable<MarketplaceItemExtended | undefined>;
  marketplaceItemType: { backLink: string; type?: string; group?: string } = { backLink: '/rewards/marketplace' };
  destroyed$: Subject<void> = new Subject<void>();
  productcode = ProductCode;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private marketplaceExtendedQuery: MarketplaceExtendedQuery,
    private marketplaceService: MarketplaceService
  ) {}

  ngOnInit() {
    this.route.paramMap.pipe(pluck('params'), takeUntil(this.destroyed$)).subscribe((param: any) => {
      this.id = param?.id;
      this.fetchMarketplace();
    });
  }

  fetchMarketplace() {
    this.marketplaceService
      .fetchMarketplaceItemById(this.id)
      .toPromise()
      .then(() => {
        this.marketplaceItem$ = this.marketplaceExtendedQuery.selectExtendedMarketplaceItem(this.id).pipe(
          tap((item) => {
            if (item) {
              this.marketplaceItemType = this.marketplaceExtendedQuery.getMarketplaceItemType(item);
            }
          })
        );
      })
      .catch(() => {
        // Redirect to 404 page if the item is not found in the database
        this.router.navigate(['/404page']);
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
