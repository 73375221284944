<ng-container *ifScreenSize="screenType.Desktop">
  <nav class="nav home-nav justify-content-between" *notInProductCode="productcode.SingleGameBuyer">
    <a class="nav-item" [routerLink]="['/']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
      <label>{{ 'nav.home' | translate }} </label>
    </a>
    <a
      class="nav-item"
      [routerLink]="['/rewards/events']"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <label>{{ 'nav.events' | translate }} </label>
    </a>
    <a
      class="nav-item"
      [routerLink]="['/rewards/marketplace']"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <label>{{ 'nav.giveaways' | translate }} </label>
    </a>
    <a
      class="nav-item"
      [routerLink]="['/rewards/raffles']"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <label>{{ 'nav.lottos' | translate }} </label>
    </a>
    <a class="nav-item" [routerLink]="['/badges']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
      <label>{{ 'nav.challenges' | translate }} </label>
    </a>
    <a
      *ifScreenSize="screenType.Desktop"
      class="nav-item"
      [routerLink]="['/rewards/wallet']"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <label>{{ 'nav.wallet' | translate }} </label>
    </a>
    <a
      class="nav-item"
      [routerLink]="['/benefits']"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
      *notInProductCode="productcode.SingleGameBuyer"
    >
      <label>{{ 'nav.benefits' | translate }} </label>
    </a>
  </nav>
</ng-container>

<nav class="nav home-nav stb-nav" *inProductCode="productcode.SingleGameBuyer">
  <a class="nav-item" [routerLink]="['/']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
    <label>{{ 'nav.home' | translate }} </label>
  </a>
  <a
    class="nav-item"
    [routerLink]="['/rewards']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    *inProductCode="productcode.SingleGameBuyer"
  >
    <label>{{ 'nav.rewards' | translate }} </label>
  </a>
  <a class="nav-item" [routerLink]="['/badges']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
    <label>{{ 'nav.challenges' | translate }} </label>
  </a>
  <a
    *ifScreenSize="screenType.Desktop"
    class="nav-item"
    [routerLink]="['/rewards/wallet']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <label>{{ 'nav.wallet' | translate }} </label>
  </a>
</nav>

<ng-container *notInProductCode="productcode.SingleGameBuyer">
  <nav class="nav nav-sth-mobile home-sth-navbar" *ifScreenSize="screenType.Mobile">
    <a class="nav-item" [routerLink]="['/']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
      <label>{{ 'nav.home' | translate }} </label>
      <div class="border-for-label nav-border-short"></div>
    </a>
    <a
      class="nav-item"
      [routerLink]="['/rewards/events']"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <label>{{ 'nav.events' | translate }} </label>
      <div class="border-for-label nav-border-short"></div>
    </a>

    <a
      class="nav-item"
      [routerLink]="['/rewards/marketplace']"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <label>{{ 'nav.giveaways' | translate }} </label>
      <div class="border-for-label nav-border-long"></div>
    </a>

    <a
      class="nav-item"
      [routerLink]="['/rewards/raffles']"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <label>{{ 'nav.lottos' | translate }} </label>
      <div class="border-for-label nav-border-long"></div>
    </a>
    <a class="nav-item" [routerLink]="['/badges']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
      <label>{{ 'nav.challenges' | translate }} </label>
      <div class="border-for-label nav-border-long"></div>
    </a>

    <a class="nav-item" [routerLink]="['/benefits']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
      <label>{{ 'nav.benefits' | translate }} </label>
      <div class="border-for-label nav-border-long"></div>
    </a>
  </nav>
</ng-container>
