import { Component, OnInit } from '@angular/core';
import { LottoService } from '@fgb/core';

@Component({
  selector: 'fgb-lottos-page',
  templateUrl: './lottos-page.component.html',
  styleUrls: ['./lottos-page.component.scss'],
})
export class LottosPageComponent implements OnInit {
  constructor(private lottoService: LottoService) {}

  ngOnInit() {
    this.lottoService.fetchLottos().toPromise();
  }
}
