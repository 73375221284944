import { Component, OnInit, Input } from '@angular/core';
import { Barcode, BarcodeQuery, MemberCardQuery, MemberDetails, MemberQuery } from '@fgb/core';
import { BarcodeService } from '@fgb/core';
import { Observable } from 'rxjs';
import { MemberCard } from '@fgb/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductCode } from 'src/app/shared/utilities/product-code';

@Component({
  selector: 'fgb-card-details-modal',
  templateUrl: './card-details-modal.component.html',
  styleUrl: './card-details-modal.component.scss',
})
export class CardDetailsModalComponent implements OnInit {
  @Input() memberId: string;
  barcode$: Observable<Barcode>;
  pointsBalance$: Observable<number | undefined>;
  ecashBalance$: Observable<number | undefined>;
  card$: Observable<MemberCard | undefined>;
  member$: Observable<MemberDetails | undefined>;
  memberDetails$: Observable<MemberDetails | undefined>;
  hasCache: boolean;
  productcode = ProductCode;

  constructor(
    private barcodeService: BarcodeService,
    private barcodeQuery: BarcodeQuery,
    private memberCardQuery: MemberCardQuery,
    private memberDetailsQuery: MemberQuery,
    private memberQuery: MemberQuery,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.barcodeService.fetchBarcode().toPromise();
    this.barcode$ = this.barcodeQuery.select();
    this.member$ = this.memberDetailsQuery.selectMemberDetails();
    this.card$ = this.memberCardQuery.selectCurrentCard();
    this.memberDetails$ = this.memberQuery.selectMemberDetails();
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
