import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { ContactService, Category, Contact } from '@fgb/core';
@Component({
  selector: 'fgb-contact-us-page',
  templateUrl: './contact-us-page.component.html',
  styleUrls: ['./contact-us-page.component.scss'],
})
export class ContactUsPageComponent implements OnInit {
  contactUsForm: FormGroup;
  categories$: Observable<Category[]>;
  submitted = false;
  sentSuccessfully: boolean = false;
  constructor(private formBuilder: FormBuilder, private contactService: ContactService) {}

  get f() {
    return this.contactUsForm.controls;
  }

  ngOnInit() {
    this.categories$ = this.contactService.getCategories();

    this.contactUsForm = this.formBuilder.group({
      category: ['', [Validators.required, this.noWhitespaceValidator]],
      message: [{ value: '', disabled: true }, [Validators.required, this.noWhitespaceValidator]],
    });
  }

  onCategoryChange() {
    this.contactUsForm.controls.message.enable();
  }

  async submit(message: Contact, isValid: boolean) {
    this.submitted = true;
    if (isValid) {
      await this.contactService.sendMessage(message);
      this.sentSuccessfully = true;
    }
  }

  public noWhitespaceValidator(control: FormControl) {
    if (control.value === '') return;
    return control.value.trim().length ? null : { whitespace: true };
  }
}
