<!--Banner-->
<div class="d-none d-lg-block">
  <!--Mixed Loyalty Nav-->
</div>

<!--Point Summary Bar-->
<div class="container">
  <fgb-points-summary-bar [backButtonLink]="'/rewards/raffles'" [backButtonTitle]="'Giveaways'"></fgb-points-summary-bar>
</div>

<!--Lotto Details-->
<div class="container px-0">
  <div class="pt-3 cursor-pointer d-none d-lg-flex align-items-center" [routerLink]="'/rewards/raffles'">
    <span class="icon material-icons text-white font-size-24 pe-2">keyboard_arrow_left</span>
    <span class="back-to-text"> {{ 'back.to.marketplace' | translate }} </span>
  </div>

  <fgbcl-lotto-details [lottoId]="id"></fgbcl-lotto-details>
</div>
