<div class="user-infomation p-3 text-primary">
  <div class="d-flex justify-content-between align-items-center">
    <h3 class="font-heading font-size-20 text-uppercase mb-0">{{ 'membercard.discount.title' | translate }}</h3>
    <i class="material-icons close-btn" (click)="closeModal()">cancel</i>
  </div>
  <hr class="bg-white mt-2" />
  <div
    class="font-praktika-normal font-size-14 text-primary"
    [innerHTML]="'membercard.infomation' | translate | markdownTranslate"
  ></div>
</div>
