<div class="container">
  <fgb-member-card [wallet]="true"></fgb-member-card>

  <div class="d-lg-block d-none mt-lg-3 mt-4 mb-0">
    <fgb-home-nav></fgb-home-nav>
  </div>
</div>

<ng-container *ngIf="walletItems?.length > 0; else noWalletItems">
  <div class="wallet-redeem-carousel pt-lg-4 pt-0">
    <div class="container">
      <fgbcl-rewards-wallet-redeem-list [unRedeemedItems]="walletItems"></fgbcl-rewards-wallet-redeem-list>
    </div>
  </div>
</ng-container>

<ng-template #noWalletItems>
  <div class="container">
    <h5 class="no-wallet-items mt-5 mb-3 fw-bold">{{ 'rewards.wallet.no.items' | translate }}</h5>
    <div class="no-wallet-items-description font-praktika-bold border rounded font-size-12 text-center text-primary py-3">
      {{ 'rewards.wallet.no.items.description' | translate }}
    </div>
  </div>
</ng-template>

<div class="container mt-3 text-center">
  <a class="font-size-14 claimed-text text-info font-praktika-bold" [routerLink]="['/rewards/claimed']">
    {{ 'view.claimed.rewards' | translate }}
  </a>
</div>
