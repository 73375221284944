import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AuthGuard,
  ProductCodeGuard,
  PopulationGuard,
  StepType,
  MemberResolver,
  LottoResolver,
  MemberCardResolver,
  BalanceResolver,
  PurchaseResolver,
  AddressBookResolver,
  CardTokenResolver,
  RegistrationStepResolver,
  LottoWalletResolver,
  AddressResolver,
  TransactionSuccessGuard,
  SeatingDetailResolver,
  CountryResolver,
  MemberLoyaltyStatusResolver,
  PopUpNotificationResolver,
  MaintenanceGuard,
  FGBPreLoadingStrategy,
  groupedWalletResolver,
} from '@fgb/core';

// General
import { MasterPageComponent } from './pages/master/master-page/master-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { FaqPageComponent } from './pages/faq-page/faq-page.component';
import { ContactUsPageComponent } from './pages/contact-us-page/contact-us-page.component';
import { TermsPageComponent } from './pages/terms-page/terms-page.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
// Loyalty
import { RewardsLayoutComponent } from './pages/rewards-layout/rewards-layout.component';
import { RewardsPageComponent } from './pages/rewards-page/rewards-page.component';
import { MarketplaceDetailPageComponent } from './pages/marketplace-detail-page/marketplace-detail-page.component';
import { MarketplacePurchaseSuccessPageComponent } from './pages/marketplace-page/marketplace-purchase-success-page/marketplace-purchase-success-page.component';
import { LottosPageComponent } from './pages/lottos-page/lottos-page.component';
import { LottoDetailPageComponent } from './pages/lotto-detail-page/lotto-detail-page.component';
import { LottosSuccessPageComponent } from './pages/lottos-page/lottos-success-page/lottos-success-page.component';
import { EventsPageComponent } from './pages/events-page/events-page.component';
import { WalletPageComponent } from './pages/wallet-page/wallet-page.component';
import { LottoWalletDetailsPageComponent } from './pages/lotto-wallet-details-page/lotto-wallet-details-page.component';
import { BadgesPageComponent } from './pages/badges-page/badges-page.component';

// Account
import { AccountDetailsPageComponent } from './pages/account-details-page/account-details-page.component';
// Events & Tickets
import { ContactsPageComponent } from './pages/contacts-page/contacts-page.component';

// Unauthenticated
import { AuthCallbackComponent } from './modules/user/components/auth/auth-callback/auth-callback.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { LoginAccountsPageComponent } from './pages/login-accounts-page/login-accounts-page.component';
import { SingleSignOnComponent } from './modules/user/components/single-sign-on/single-sign-on.component';
import { MaintenancePageComponent } from './pages/maintenance-page/maintenance-page.component';
// Registration
import { RegistrationPaymentComponent } from './modules/registration/pages/steps/registration-payment/registration-payment.component';
// Population Pages
import { TestPopHomePageComponent } from './pages/test-pop-home-page/test-pop-home-page.component';
// Partners Pages
import { LottosTermsPageComponent } from './pages/lottos-terms-page/lottos-terms-page.component';
import { MarketplaceTermsPageComponent } from './pages/marketplace-terms-page/marketplace-terms-page.component';
import { TmCallbackComponent } from './shared/components/manage-tickets/tm-callback/tm-callback.component';
import { TmAuthCallbackComponent } from './shared/components/providers/tm/tm-auth-callback/tm-auth-callback.component';
import { V1SingleSignOnComponent } from './modules/user/components/v1-single-sign-on/v1-single-sign-on.component';
// Offer Pages
import { NotificationManagementPageComponent } from './pages/notification-management-page/notification-management-page.component';
import { ProviderCallbackComponent } from './shared/components/providers/provider-callback/provider-callback.component';
import { UserResetPasswordComponent } from './modules/user/components/user-reset-password/user-reset-password.component';
import { UserChangePasswordComponent } from './modules/user/components/user-change-password/user-change-password.component';
import { RegistrationLayoutPageComponent } from './modules/registration/pages/registration-layout-page/registration-layout-page.component';
import { WalletClaimedPageComponent } from './pages/wallet-claimed-page/wallet-claimed-page.component';
import { MarketplacePageComponent } from './pages/marketplace-page/marketplace-page.component';
import { BenefitsPageComponent } from './pages/benefits-page/benefits-page.component';
import { RedirectNotificationComponent } from './shared/components/notification-redirect/notification-redirect.component';
import { provideRegistrationConfig } from '@fgb/portal-component-library/src/lib/registration';
import { MarketplaceWalletPageComponent } from './pages/marketplace-wallet-page/marketplace-wallet-page.component';
import { ProductCode } from './shared/utilities/product-code';
import { ScorecardDescriptionComponent } from '@fgb/portal-component-library/src/lib/loyalty/scorecards';

const productCode = ProductCode;

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [MaintenanceGuard],
    component: MasterPageComponent,
    resolve: {
      member: MemberResolver,
      memberCard: MemberCardResolver,
      balances: BalanceResolver,
      popupNotifications: PopUpNotificationResolver,
    },
    children: [
      {
        path: '',
        canActivate: [PopulationGuard],
        data: {
          exclude: ['testPop'],
          popRedirect: 'test',
        },
        component: HomePageComponent,
      },
      {
        path: 'test',
        canActivate: [PopulationGuard],
        data: {
          include: ['testPop'],
          popRedirect: '',
        },
        component: TestPopHomePageComponent,
      },
      {
        path: 'rewards',
        component: RewardsLayoutComponent,
        children: [
          {
            path: '',
            component: RewardsPageComponent,
          },

          {
            path: 'marketplace',
            children: [
              {
                path: '',
                component: MarketplacePageComponent,
              },
              {
                path: 'details/:id',
                children: [
                  {
                    path: '',
                    resolve: {
                      balances: BalanceResolver,
                      cardTokens: CardTokenResolver,
                      country: CountryResolver,
                    },
                    component: MarketplaceDetailPageComponent,
                  },
                  {
                    path: 'terms',
                    component: MarketplaceTermsPageComponent,
                  },
                ],
              },
              {
                path: 'purchase/success',
                canActivate: [TransactionSuccessGuard],
                component: MarketplacePurchaseSuccessPageComponent,
                resolve: {
                  balances: BalanceResolver,
                  purchase: PurchaseResolver,
                },
              },
            ],
          },
          {
            path: 'events',
            component: EventsPageComponent,
            canActivate: [ProductCodeGuard],
            data: {
              excludeCodes: productCode.SingleGameBuyer,
              codesRedirect: ['/']
            },
          },

          {
            path: 'raffles',
            children: [
              {
                path: '',
                component: LottosPageComponent,
                resolve: { lottos: LottoResolver, balances: BalanceResolver },
              },
              {
                path: 'details/:id',
                children: [
                  {
                    path: '',
                    resolve: { balances: BalanceResolver, lottos: LottoResolver },
                    component: LottoDetailPageComponent,
                  },
                  {
                    path: 'terms',
                    component: LottosTermsPageComponent,
                    resolve: { lottos: LottoResolver },
                  },
                ],
              },
              {
                path: 'purchase/success',
                canActivate: [TransactionSuccessGuard],
                component: LottosSuccessPageComponent,
              },
            ],
          },
          {
            path: 'wallet',
            children: [
              {
                path: '',
                component: WalletPageComponent,
                resolve: {
                  purchases: PurchaseResolver,
                  lottoWalletItems: LottoWalletResolver,
                },
              },

              {
                path: 'details/:id',
                component: MarketplaceWalletPageComponent,
                resolve: { purchases: PurchaseResolver },
              },
              {
                path: 'raffledetails/:id',
                component: LottoWalletDetailsPageComponent,
                resolve: { lottoWalletItems: LottoWalletResolver },
              },
            ],
            resolve: {
              purchases: PurchaseResolver,
              lottoWalletItems: LottoWalletResolver,
              walletItems: groupedWalletResolver({ redeemed: false})

            },
          },
          {
            path: 'claimed',
            children: [
              {
                path: '',
                component: WalletClaimedPageComponent,
                resolve: {
                  purchases: PurchaseResolver,
                  lottoWalletItems: LottoWalletResolver,
                },
              },
            ],
            resolve: {
              walletItems: groupedWalletResolver({ redeemed: true})
            }
          },
        ],
      },
      {
        path: 'notification',
        children: [
          {
            path: 'marketplace/:id',
            component: RedirectNotificationComponent,
            data: { Datatype: '1' },
          },
        ],
      },
      {
        path: 'account',
        component: AccountDetailsPageComponent,
        resolve: {
          member: MemberResolver,
          balances: BalanceResolver,
          seating: SeatingDetailResolver,
          memberLoyaltyStatus: MemberLoyaltyStatusResolver,
        },
      },

      { path: 'faq', component: FaqPageComponent },
      { path: 'terms', component: TermsPageComponent },
      { path: 'contact', component: ContactUsPageComponent },
      { path: 'badges', component: BadgesPageComponent },
      { path: 'scorecard-description/:id', component: ScorecardDescriptionComponent },
      {
        path: 'benefits',
        component: BenefitsPageComponent,
        canActivate: [ProductCodeGuard],
        data: {
          excludeCodes: productCode.SingleGameBuyer,
          codesRedirect: ['/']
        },
      },
      {
        path: 'contacts',
        component: ContactsPageComponent,
        resolve: { addressBookResolver: AddressBookResolver },
      },

      // Club Pages

      { path: 'notificationmanagement', component: NotificationManagementPageComponent },

      { path: '404page', component: NotFoundPageComponent },
      { path: 'signin-ticketmaster', component: TmCallbackComponent },
      {
        path: 'provider/:providerid',
        component: ProviderCallbackComponent,
      },
    ],
  },

  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginPageComponent, canActivate: [MaintenanceGuard] },
  { path: 'login/accounts', component: LoginAccountsPageComponent, canActivate: [MaintenanceGuard] },
  { path: 'reset-password', component: UserResetPasswordComponent },
  { path: 'change-password', component: UserChangePasswordComponent },
  {
    path: 'register',
    canActivateChild: [MaintenanceGuard],
    resolve: { registrationStep: RegistrationStepResolver, country: CountryResolver },
    children: [
      {
        path: 'payment',
        children: [
          {
            path: '',
            component: RegistrationPaymentComponent,
            data: { registrationStep: StepType.Payment },
            resolve: { member: MemberResolver, address: AddressResolver },
          },
        ],
      },
    ],
  },
  {
    path: 'register',
    component: RegistrationLayoutPageComponent,
    loadChildren: () => import('@fgb/portal-component-library/src/lib/registration').then((m) => m.RegistrationModule),
    providers: [provideRegistrationConfig()],
  },
  { path: 'callback', component: AuthCallbackComponent },
  { path: 'sso', component: SingleSignOnComponent },
  { path: 'login/singleSignOn/:providerId', component: SingleSignOnComponent },
  { path: 'login/singleSignOn/:providerId/:clubId', component: SingleSignOnComponent },
  { path: 'login/ss/login.aspx', component: V1SingleSignOnComponent },
  { path: 'maintenance', component: MaintenancePageComponent },
  { path: 'tm-auth-callback', component: TmAuthCallbackComponent },
  { path: '**', redirectTo: '404page' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: FGBPreLoadingStrategy,
      bindToComponentInputs: true
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
