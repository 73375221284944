import { Component, OnInit } from '@angular/core';
import { NotificationCenterQuery, NotificationCenterService } from '@fgb/core';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

@Component({
  selector: 'fgb-notification-button',
  templateUrl: './notification-button.component.html',
  styleUrl: './notification-button.component.scss',
})
export class NotificationButtonComponent implements OnInit {
  unseenNotificationCount$: Observable<number>;
  isOpen: boolean;
  screenSize = ScreenType;

  constructor(private notificationService: NotificationCenterService, private notificationQuery: NotificationCenterQuery) {}

  ngOnInit(): void {
    this.unseenNotificationCount$ = this.notificationQuery.unseenNotificationsCount$;
  }

  toggleNotificationCenter(open: boolean) {
    this.isOpen = open;
    this.notificationService.updateIsOpen(open);
  }

  closeDropDown(dropdown: NgbDropdown) {
    dropdown.close();
  }
}
