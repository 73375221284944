<div class="full-page w-100 d-md-flex d-block px-0">
  <!--start Side-->
  <img class="shared-login-banner d-md-block d-none" draggable="false" src="assets/images/login-registration/login-banner.png" />
  <div class="bg-primary d-flex w-100 d-md-none m-auto mobile-header"></div>
  <!--end Side-->
  <div class="p-4 right-side-login">
    <form class="reset-password container" (submit)="submit()" [formGroup]="formReset">
      <h1 class="reset-password-title my-5 text-center">{{ 'reset.pw.title' | translate }}</h1>
      <div class="form-input">
        <label>{{ 'reset.pw.label' | translate }}</label>
        <div class="m-0 row">
          <input class="form-control col-12 col-lg-9 me-2" formControlName="email" />
          <button [disabled]="formReset.invalid" type="submit" class="btn btn-success col-12 col-lg-2 mt-2 mt-lg-0">
            {{ 'reset.pw.btn' | translate }}
          </button>
          <div class="text-danger font-size-12 mt-2" [style.visibility]="checkForm['email'] ? '' : 'hidden'">
            {{ 'reset.pw.warn' | translate }}
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<ng-template #alert let-modal>
  <div class="modal-body">
    <div class="text-center mt-4">{{ contentModal }}</div>
  </div>
  <div class="modal-footer border-top-0">
    <button type="button" class="btn btn-success" (click)="modal.close()">OK</button>
  </div>
</ng-template>
