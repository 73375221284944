import { Inject, Injectable, InjectionToken, Optional } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { DOCUMENT } from "@angular/common";


export const MODE = new InjectionToken<'light'| 'dark'>('mode');

@Injectable({
    providedIn: 'root'
})
export class ModeService{

    private _mode: 'light'| 'dark' = 'light';
    private _modeChangeSubject = new BehaviorSubject<string>(this._mode);

    constructor(@Inject(MODE) @Optional() private mode: 'light'| 'dark', @Inject(DOCUMENT) private document: Document){
        if( this.mode ){
            this._mode = this.mode;
        }
    }

    get modeChange$(){
        return this._modeChangeSubject.asObservable();
    }

    set(mode: 'light'| 'dark'): void{
        this._mode = mode;
        this._modeChangeSubject.next(this._mode);
        this.document.getElementsByTagName('html')[0].setAttribute('data-bs-theme', this._mode);
    }

    init(): void{
        this.set(this._mode);
    }
}