<ng-container *ngIf="card$ | async as memberCard; else loadingRef">
  <div class="qr d-block text-center" *ngIf="barcode$ | async as barcode">
    <div class="d-flex justify-content-between mb-4">
      <h5 class="mb-1 card-name text-primary font-size-20" *ngIf="member$ | async as memberDetails">
        {{ memberDetails.FirstName }}
      </h5>
      <span class="material-icons text-white cursor-pointer" (click)="closeModal()"> close </span>
    </div>
    <div class="row h-50">
      <qrcode [qrdata]="barcode.Barcode" [width]="151" [errorCorrectionLevel]="'M'" [elementType]="'svg'"></qrcode>
    </div>
    <ng-container *notInProductCode="productcode.SingleGameBuyer">
      <div class="text-start font-size-14 font-praktika-normal mt-3">
        {{ 'barcode.description' | translate }}
      </div>
    </ng-container>

    <ng-container *inProductCode="productcode.SingleGameBuyer">
      <div class="text-start font-size-14 font-praktika-normal mt-3">
        {{ 'barcode.description.sgb' | translate }}
      </div>
    </ng-container>

    <ng-container *notInProductCode="productcode.SingleGameBuyer">
      <div class="font-praktika-bold text-start mt-4 font-size-14">
        <div>
          {{ 'member.card.discount.label' | translate }}
        </div>
        <div>{{ memberCard.DiscountLevel }}%</div>
      </div>
    </ng-container>
  </div>
</ng-container>

<ng-template #loadingRef>
  <fgb-loading></fgb-loading>
</ng-template>
