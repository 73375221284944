import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'fgb-notification-redirect',
  templateUrl: './notification-redirect.component.html',
  styleUrls: ['./notification-redirect.component.scss'],
})
export class RedirectNotificationComponent implements OnInit {
  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    const dataType = this.route.snapshot.data.Datatype;
    this.router.navigate(['rewards/wallet/details/' + id + '_' + dataType]);
  }
}
