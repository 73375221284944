<!-- Points Summary -->
<div class="container" *ngIf="marketplaceItem$ | async">
  <fgb-points-summary-bar
    [backButtonLink]="marketplaceItemType.backLink"
    [marketplaceItemGroup]="marketplaceItemType.group"
  ></fgb-points-summary-bar>
</div>

<div class="container px-0">
  <ng-container *notInProductCode="productcode.SingleGameBuyer">
    <div class="pt-3 cursor-pointer d-none d-lg-flex align-items-center" [routerLink]="[marketplaceItemType.backLink]">
      <span class="icon material-icons text-primary font-size-24 pe-2">keyboard_arrow_left</span>
      <span class="back-to-text"> {{ 'back.to.marketplace' | translate }} </span>
    </div>
  </ng-container>

  <ng-container *inProductCode="productcode.SingleGameBuyer">
    <div class="pt-3 cursor-pointer d-none d-lg-flex align-items-center" [routerLink]="'/rewards'">
      <span class="icon material-icons text-primary font-size-24 pe-2">keyboard_arrow_left</span>
      <span class="back-to-text"> {{ 'back.to.marketplace' | translate }} </span>
    </div>
  </ng-container>
  <fgbcl-marketplace-details [itemId]="id" [actionLabels]="actionLabels"></fgbcl-marketplace-details>
</div>
