<nav class="navbar navbar-dark navbar-expand-lg d-none d-lg-flex bg-primary-dark">
  <a
    class="navbar-brand py-0"
    [routerLink]="['/']"
    routerLinkActive="router-link-active"
    attr.aria-label="{{ 'nav.home.page.screenreader' | translate: { fgbDefault: 'Move to home page' } }}"
  >
    <label id="homeLink" class="accessibility-only">Go To Home Page</label>
    <img alt="" class="h-100" aria-labelledby="homeLink" src="assets/images/logo.png" draggable="false" />
  </a>

  <button
    class="navbar-toggler"
    type="button"
    (click)="isCollapsed = !isCollapsed"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    attr.aria-label="{{ 'nav.toggle.screenreader' | translate }}"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="navbar-collapse" [ngbCollapse]="isCollapsed" id="navbarSupportedContent">
    <div class="nav-container"></div>
    <div class="d-flex align-items-center flex-nowrap my-2 my-lg-0">
      <!--Notifications-->
      <ng-container *notInProductCode="productcode.NonRenewing">
        <div ngbDropdown class="position-static" (openChange)="toggleNotificationCenter($event)" focusTab>
          <button
            class="notification-btn btn px-0 d-flex"
            id="notificationDropdown"
            attr.aria-label="{{ 'nav.notification.screenreader' | translate }}"
            tabindex="-1"
            ngbDropdownToggle
          >
            <span class="icon notification-bell text-white material-icons material-mobile-nav px-4 me-4 border-right"
              >notifications</span
            >
            <ng-container *ngIf="unseenNotificationCount$ | async">
              <div class="count-notification">{{ unseenNotificationCount$ | async }}</div>
            </ng-container>
          </button>

          <div ngbDropdownMenu aria-labelledby="notificationDropdown" class="notification-dropdown dropdown-menu">
            <div class="notification-header d-flex justify-content-between align-items-center">
              <label class="text-black fw-bold font-size-14"> {{ 'notification.title' | translate }}</label>
              <span class="icon material-icons cursor-pointer text-white" ngbDropdownToggle>close</span>
            </div>
            <fgbcl-notification-hub></fgbcl-notification-hub>
          </div>
        </div>
      </ng-container>
      <div class="member" ngbDropdown #ddAccount="ngbDropdown" [autoClose]="'outside'" focusTab>
        <button
          class="nav-link btn d-flex align-items-center px-0 justify-content-end"
          id="namedropdown"
          ngbDropdownToggle
          attr.aria-label="{{ 'nav.account.dropdown.screenreader' | translate }}"
          tabindex="-1"
        >
          <div *ngIf="memberDetails$ | async as memberDetails; else memberDetailsLoading" class="user-box h-100 d-flex">
            <div class="name font-size-14 text-start fw-bold text-white text-uppercase">
              {{ memberDetails!.FirstName }}
            </div>
            <span class="icon material-icons">arrow_drop_down</span>
          </div>
        </button>

        <div
          ngbDropdownMenu
          (click)="closeDropDown(ddAccount)"
          aria-labelledby="namedropdown"
          class="text-center dropdown-menu account-dropdown mt-2"
        >
          <ng-container *notInProductCode="productcode.NonRenewing">
            <div class="text-info">
              <a
                [routerLink]="['/account']"
                routerLinkActive="router-link-active"
                class="d-flex align-items-center justify-content-center account-btn text-white py-0 font-size-14 nav-item-fixed-height rounded-top"
                ngbDropdownItem
              >
                <div class="nav-border bg-primary">
                  <span class="icon material-icons material-dropdown-icon me-2" aria-hidden="true">person</span>

                  <span class="text-lowercase font-praktika-normal">Account</span>
                </div>
              </a>
            </div>

            <div class="text-white">
              <a
                [routerLink]="['/rewards/wallet']"
                routerLinkActive="router-link-active"
                class="d-flex align-items-center justify-content-center account-btn text-white py-0 nav-item-fixed-height my-10px"
                ngbDropdownItem
              >
                <div class="nav-border bg-primary">
                  <span class="icon material-icons material-dropdown-icon me-2" aria-hidden="true">account_balance_wallet</span>
                  <div class="dropdown-link text-start">
                    <div class="font-size-14 font-praktika-normal text-lowercase">{{ 'nav.wallet' | translate }}</div>
                  </div>
                </div>
              </a>
            </div>
          </ng-container>
          <button
            class="logout-btn nav-item-fixed-height rounded-bottom p-0"
            (click)="logout()"
            ngbDropdownItem
            attr.aria-label="{{ 'nav.log.out.screenreader' | translate }}"
          >
            <div class="nav-border bg-primary">
              <span class="icon material-icons material-dropdown-icon me-2 text-white" aria-hidden="true"
                >power_settings_new</span
              >
              <div class="dropdown-link">
                <div class="text-lowercase font-praktika-normal py-1 font-size-14 text-white" ngbDropdownItem (click)="logout()">
                  {{ 'nav.log.out' | translate }}
                </div>
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</nav>

<ng-template #memberDetailsLoading>
  <div class="user-box d-block text-center text-on-primary px-4">
    <fgbcl-loading [size]="'small'"></fgbcl-loading>
  </div>
</ng-template>
