<div class="full-page">
  <img class="shared-login-banner" draggable="false" src="assets/images/my-grizzlies-login.png" />
  <div class="content">
    <span class="icon material-icons"> engineering </span>
    <div class="text-center title">
      {{ 'maintenancepage.currentlyunavailable.title' | translate }}
    </div>
    <div class="maintenance-text" [innerHTML]="'maintenancepage.currentlyunavailable.text' | translate | markdownTranslate"></div>
  </div>
</div>
