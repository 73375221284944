<div class="container" *notInProductCode="productcode.NonRenewing">
  <fgb-barcode></fgb-barcode>
  <div class="mt-3 mb-0">
    <fgb-home-nav></fgb-home-nav>
  </div>

  <fgbcl-announcements></fgbcl-announcements>

  <ng-container *notInProductCode="productcode.SingleGameBuyer">
    <ng-container *ngIf="marketplaceItems$ | async as marketplaceItems">
      <ng-container *ngIf="marketplaceItems.length">
        <div class="d-flex justify-content-between">
          <h2 class="heading-title mt-0 mb-0">{{ 'featured.events' | translate: { fgbdefault: 'FEATURED EVENTS' } }}</h2>
          <span class="d-flex align-items-center">
            <a class="home-link" [routerLink]="['/rewards/events']">
              {{ 'home.marketplace.link' | translate }}
            </a>
            <span class="material-icons text-white">keyboard_arrow_right</span>
          </span>
        </div>
        <owl-carousel-o [options]="customOptions">
          <ng-container *ngFor="let marketplaceItem of marketplaceItems">
            <ng-template carouselSlide [width]="marketplaceItems.length <= 2 ? rewardsWidth : 0">
              <fgbcl-marketplace-list-item
                [marketplaceItem]="marketplaceItem"
                [marketplaceItemTypes]="[3]"
              ></fgbcl-marketplace-list-item>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="rewardMarketplaceItems$ | async as marketplaceItems">
      <ng-container *ngIf="marketplaceItems.length">
        <div class="d-flex justify-content-between pt-3">
          <h2 class="heading-title mt-0 mb-0">{{ 'featured.giveaways' | translate: { fgbdefault: 'FEATURED GIVEAWAYS' } }}</h2>
          <span class="d-flex align-items-center">
            <a class="home-link" [routerLink]="['/rewards/marketplace']">
              {{ 'home.events.link' | translate }}
            </a>
            <span class="material-icons text-white">keyboard_arrow_right</span>
          </span>
        </div>
        <owl-carousel-o [options]="customOptions">
          <ng-container *ngFor="let marketplaceItem of marketplaceItems">
            <ng-template carouselSlide [width]="marketplaceItems.length <= 2 ? rewardsWidth : 0">
              <fgbcl-marketplace-list-item
                [marketplaceItem]="marketplaceItem"
                [marketplaceItemTypes]="[0, 1, 2]"
              ></fgbcl-marketplace-list-item>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-container *inProductCode="productcode.SingleGameBuyer">
    <fgbcl-featured-rewards></fgbcl-featured-rewards>

    <div class="mt-3">
      <fgbcl-scorecard-carousel [icon]="'chevron_right'" [showAllView]="true" link="/badges"></fgbcl-scorecard-carousel>
    </div>
  </ng-container>
</div>

<ng-container *inProductCode="productcode.NonRenewing">
  <ng-container *ngIf="memberDetails$ | async as memberDetails">
    <div class="container pt-4">
      <div class="name-title">
        <div class="font-praktika-bold font-size-20 border-bottom pb-2 name-title">
          <span>{{ 'member.card.hello' | translate }}, </span><span>{{ memberDetails.FirstName }}</span>
        </div>
      </div>

      <a href="{{ 'non.renewing.membership.url' | translate }}" target="_blank">
        <img class="w-100 h-100 pt-3 pb-4" src="/assets/images/non-renewing/non-renewing-sales.jpg" />
      </a>

      <div class="card non-renewing-card text-center">
        <div class="non-renewing-title pb-3 font-size-14">
          {{ 'non.renewing.membership.title' | translate }}
        </div>

        <div
          class="non-renewing-desc font-size-12"
          [innerHTML]="'non.renewing.membership.desc' | translate | markdownTranslate"
        ></div>
      </div>
    </div>
  </ng-container>
</ng-container>
