<div class="full-page w-100 d-flex align-items-center justify-content-center">
  <img class="shared-login-banner" draggable="false" src="assets/images/my-grizzlies-login.png" />
  <div class="content">
    <label class="title">{{ 'login.title' | translate: { fgbdefault: 'WELCOME FANS' } }}</label>
    <div class="panel-text font-size-14">
      {{
        'login.welcome.message' | translate: { fgbdefault: 'Log in with your club account below to access your online account.' }
      }}
    </div>
    <fgb-tm-user-login></fgb-tm-user-login>

    <ng-container *ngIf="errorCode$ | async as errorCode">
      <div class="text-white text-center mt-5 pb-5" [innerHTML]="errorCode | translate | markdownTranslate"></div>
    </ng-container>
  </div>
</div>
