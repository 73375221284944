<div class="login-title-section">
  <fgbcl-errors></fgbcl-errors>
</div>

<div class="form-group">
  <button
    (click)="loginTM()"
    class="btn text-white d-flex align-items-center justify-content-center btn-block login-button w-100"
  >
    <span class="font-size-14 praktika-extended-font">{{ 'login.page.button' | translate }}</span>
    <span><img src="assets/images/ticketmaster.svg" class="tm-logo ps-2" /></span>
  </button>
</div>
