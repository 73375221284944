<div class="card rounded border">
  <div *ngIf="member$ | async as member">
    <label class="mb-0 personal-info">{{ 'account.details.email' | translate }}</label>
    <div class="d-flex align-items-center p-2 m-0 mt-1 bg-muted rounded email">
      <span class="icon material-icons" aria-hidden="true">email</span>
      <span class="ms-2 text-primary praktika-medium font-size-12 d-flex align-items-center">{{ member.EmailAddress1 }}</span>
    </div>
  </div>
</div>
<ng-template #loadingRef>
  <fgbcl-loading></fgbcl-loading>
</ng-template>
