<div class="container">
  <fgb-member-card></fgb-member-card>
  <div class="mt-3 mb-0">
    <fgb-home-nav></fgb-home-nav>
  </div>
</div>

<div class="container mt-40px">
  <fgbcl-marketplace-list
    [isList]="true"
    [showSortBar]="false"
    [marketplaceItemTypes]="[3, 5]"
    [eventItemView]="true"
  ></fgbcl-marketplace-list>
</div>
