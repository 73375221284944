<div class="container">
  <fgb-member-card></fgb-member-card>
  <div class="mt-3 mb-0">
    <fgb-home-nav></fgb-home-nav>
  </div>
</div>

<fgb-filter-modal
  [filterTypesList]="filterTypesList"
  (filterTypesListEmitter)="handleFilterTypesListEmitter($event)"
  [selectedValues]="selectedValues"
  (selectedValuesEmitter)="handleSelectedValuesEmitter($event)"
  [showOffCanvas]="showOffCanvas"
  (showOffCanvasEmitter)="handleShowOffCanvasEmitter()"
>
</fgb-filter-modal>

<div class="container mt-3">
  <fgbcl-mixed-loyalty-list
    [onlyFavourites]="true"
    [filterOptionsList]="selectedValues"
    [pageSize]="100"
  ></fgbcl-mixed-loyalty-list>
</div>
