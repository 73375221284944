<div role="contentinfo" class="footer text-white text-center">
  <ng-container *notInProductCode="productcode.NonRenewing">
    <div class="footer-quicklinks w-100 d-none d-lg-block">
      <div class="footer-container links fw-bold font-size-12">
        <a class="text-white" [routerLink]="['/contact']">{{ 'nav.contact.us' | translate }}</a>
        <a class="text-white" [routerLink]="['/terms']">{{ 'nav.terms' | translate }}</a>
        <a class="text-white" [routerLink]="['/faq']">{{ 'nav.faq' | translate }}</a>
      </div>
    </div>
  </ng-container>
  <div class="bg-footer text-white font-size-10 d-flex align-items-center justify-content-center w-100 footer-text">
    {{ 'footer.text.title' | translate }} <br />{{ 'footer.text.description' | translate }}
  </div>
</div>
