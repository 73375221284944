<div class="container">
  <fgb-member-card></fgb-member-card>
  <div class="mt-3 mb-0">
    <fgb-home-nav></fgb-home-nav>
  </div>
</div>

<div class="container mt-4">
  <div class="row">
    <div class="col-lg-4 col-12">
      <img class="benefit-image rounded" src="assets/images/benefits/NxtGen.png" draggable="false" />
      <div class="benefit-text">
        <h5 class="benefit-title mb-0">{{ 'benefit.nextgen.heading' | translate }}</h5>
        <div class="benefit-tenure">{{ 'benefit.nextgen.tenure' | translate }}</div>
        <div class="benefit-description" [innerHTML]="'benefit.nextgen.description' | translate | markdownTranslate"></div>
      </div>
    </div>
    <div class="col-lg-4 col-12">
      <img class="benefit-image rounded" src="assets/images/benefits/AllHeart.png" draggable="false" />
      <div class="benefit-text">
        <h5 class="benefit-title mb-0">{{ 'benefit.allheart.heading' | translate }}</h5>
        <div class="benefit-tenure">{{ 'benefit.allheart.tenure' | translate }}</div>
        <div class="benefit-description" [innerHTML]="'benefit.allheart.description' | translate | markdownTranslate"></div>
      </div>
    </div>
    <div class="col-lg-4 col-12">
      <img class="benefit-image rounded" src="assets/images/benefits/BelieveMemphis.png" draggable="false" />
      <div class="benefit-text">
        <h5 class="benefit-title mb-0">{{ 'benefit.believememphis.heading' | translate }}</h5>
        <div class="benefit-tenure">{{ 'benefit.believememphis.tenure' | translate }}</div>
        <div class="benefit-description" [innerHTML]="'benefit.believememphis.description' | translate | markdownTranslate"></div>
      </div>
    </div>
    <div class="col-lg-4 col-12 pt-lg-5">
      <img class="benefit-image rounded" src="assets/images/benefits/GritGrind.png" draggable="false" />
      <div class="benefit-text">
        <h5 class="benefit-title mb-0">{{ 'benefit.gritgrind.heading' | translate }}</h5>
        <div class="benefit-tenure">{{ 'benefit.gritgrind.tenure' | translate }}</div>
        <div class="benefit-description" [innerHTML]="'benefit.gritgrind.description' | translate | markdownTranslate"></div>
      </div>
    </div>
    <div class="col-lg-4 col-12 pt-lg-5">
      <img class="benefit-image rounded" src="assets/images/benefits/FirstGen.png" draggable="false" />
      <div class="benefit-text">
        <h5 class="benefit-title mb-0">{{ 'benefit.firstgen.heading' | translate }}</h5>
        <div class="benefit-tenure">{{ 'benefit.firstgen.tenure' | translate }}</div>
        <div class="benefit-description" [innerHTML]="'benefit.firstgen.description' | translate | markdownTranslate"></div>
      </div>
    </div>
    <div class="col-lg-4 col-12 pt-lg-5">
      <img class="benefit-image rounded" src="assets/images/benefits/Premium.png" draggable="false" />
      <div class="benefit-text">
        <h5 class="benefit-title mb-0">{{ 'benefit.premium.heading' | translate }}</h5>
        <div class="benefit-tenure">{{ 'benefit.premium.tenure' | translate }}</div>
        <div class="benefit-description" [innerHTML]="'benefit.premium.description' | translate | markdownTranslate"></div>
      </div>
    </div>
  </div>
</div>
