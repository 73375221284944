<ng-container *ifScreenSize="screenType.Mobile">
  <ng-container *ngIf="isWalletPage">
    <div class="text-primary" *ngIf="barcode$ | async as barcode">
      <div class="d-flex flex-wrap flex-column justify-content-center align-items-center shadow">
        <div class="qr w-100 bg-white mb-2 text-center rounded">
          <qrcode [qrdata]="barcode.Barcode" [size]="256" [level]="'M'" [usesvg]="true"></qrcode>
        </div>
        <div class="text-center text-white font-size-14 praktika-medium mb-3 px-3">{{ 'barcode.info' | translate }}</div>
      </div>
    </div>
  </ng-container>
</ng-container>

<fgb-member-card></fgb-member-card>

<ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.ecash'">
  <ng-container *ifScreenSize="screenType.Mobile">
    <div class="use-grizzbucks">
      <div class="font-size-14 text-white praktika-extended-font me-3">{{ 'barcode.use.grizzbuck.text' | translate }}</div>
      <div class="toggle">
        <fgbcl-cash-back-toggle></fgbcl-cash-back-toggle>
      </div>
    </div>
  </ng-container>
</ng-container>
