import { assertInInjectionContext, Component, DestroyRef, inject, OnInit } from '@angular/core';
import { ApiService, MemberService } from '@fgb/core';
import { map, Observable, switchMap, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export function getRepresentative(): any{
  assertInInjectionContext(getRepresentative);
  const api = inject(ApiService);
  const memberService = inject(MemberService);
  return memberService.getRepresentative().pipe(
    switchMap(rep => api.getFile(`content/getrepimage?repid=${rep.Id}`).pipe(
      map(data => ({ details: rep , image: data }))
    ))
  );
}
  @Component({
  selector: 'fgb-my-representative',
  templateUrl: './my-representative.component.html',
  styleUrl: './my-representative.component.scss',
})
export class RepresentativeComponent implements OnInit {
  representative$: Observable<any> = getRepresentative();
  repImage: any;
  private destroyRef = inject(DestroyRef);

  constructor() {}

  ngOnInit(): void {
    this.representative$.pipe(
      tap(res => {
        if( res.image ){
          this.createImageFromBlob(res.image);
        }
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }
  
  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
       this.repImage = reader.result;
    }, false);
 
    if (image) {
       reader.readAsDataURL(image);
    }
 }

}
