<h1 class="accessibility-only">Badges</h1>

<div class="container">
  <fgb-barcode></fgb-barcode>
  <div class="mt-3 mb-1">
    <fgb-home-nav></fgb-home-nav>
  </div>
</div>

<div class="mt-lg-2 mt-0">
  <fgbcl-badge-achieved />
</div>
<!--Scorecard -->
<div class="scorecard-list-container">
  <fgbcl-scorecard-list-view [icon]="'chevron_right'" [showPlaceholder]="true"></fgbcl-scorecard-list-view>
</div>
