import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';
import { ProductCode } from 'src/app/shared/utilities/product-code';

@Component({
  selector: 'fgb-home-nav',
  templateUrl: './home-nav.component.html',
  styleUrls: ['./home-nav.component.scss'],
})
export class HomeNavComponent implements OnInit, AfterViewInit {
  screenType = ScreenType;
  productcode = ProductCode;
  constructor() { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    setTimeout(() => {
      let accountNav = document.getElementsByClassName('home-sth-navbar')[0];
      let children = accountNav?.children || [];

    if( accountNav && children?.length > 0 ) {
        for (let i = 0; i < children.length; i++) {
          if (children[i].classList.contains('active')) {
            accountNav.scrollLeft =
              (children[i] as HTMLDivElement).offsetLeft -
              accountNav.getBoundingClientRect().width / 2 +
              (children[i] as HTMLDivElement).getBoundingClientRect().width / 2;
          }
        }
      }
    });
  }
}
