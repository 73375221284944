<div class="container">
  <fgb-member-card></fgb-member-card>
  <div class="mt-3 mb-0">
    <fgb-home-nav></fgb-home-nav>
  </div>
</div>

<div class="container mt-30px">
  <fgbcl-lottos-list [isList]="true"></fgbcl-lottos-list>
</div>
