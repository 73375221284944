import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'fgb-user-infomation-modal',
  templateUrl: './user-infomation-modal.component.html',
  styleUrls: ['./user-infomation-modal.component.scss']
})
export class UserInfomationModalComponent implements OnInit {

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
