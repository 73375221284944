<ng-container
  *ngIf="
    { memberDetails: memberDetails$ | async, memberCard: memberCard$ | async, ecash: ecash$ | async } as memberInfo;
    else loadingRef
  "
>
  <div class="member-card text-white">
    <div class="member-card-title mb-2" [routerLink]="['/account']">
      <div class="name text-primary">
        <span>{{ 'member.card.hello' | translate }}, </span><span>{{ memberInfo.memberDetails.FirstName }}</span>
      </div>
      <div class="material-icons text-secondary" *ngIf="!isAccountPage">arrow_outward</div>
    </div>
    <ng-container *ifScreenSize="screenType.Mobile">
      @if (wallet) {
        <div class="member-card-wallet card rounded border">
          <ng-container *ngIf="{ barcode: barcode$ | async } as member">
            <ng-container *ngIf="member.barcode.Barcode; else loadingRef">
              <div class="qrcode-wrapper mt-3">
                <qrcode
                  [qrdata]="member.barcode.Barcode"
                  [width]="131"
                  [errorCorrectionLevel]="'M'"
                  [elementType]="'svg'"
                  [margin]="0"
                ></qrcode>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *notInProductCode="productcode.SingleGameBuyer">
            <div class="scan-text text-center font-size-14 font-praktika-normal mt-3 mb-2">
              {{ 'barcode.description' | translate }}
            </div>
          </ng-container>

          <ng-container *inProductCode="productcode.SingleGameBuyer">
            <div class="scan-text text-center font-size-14 font-praktika-normal mt-3 mb-2">
              {{ 'barcode.description.sgb' | translate }}
            </div>
          </ng-container>
          <ng-container *ngIf="memberInfo.memberCard.DiscountLevel">
            <div class="card-details mt-2 mb-1">
              <div class="detail-info font-size-14 font-praktika-bold">
                <label class="mb-0">{{ 'member.card.discount.label' | translate }}</label>
                <div class="value">{{ memberInfo.memberCard.DiscountLevel }}%</div>
              </div>
            </div>
          </ng-container>
        </div>
      } @else {
        <ng-container *ngTemplateOutlet="cardBody; context: { $implicit: memberInfo }"></ng-container>
      }
    </ng-container>

    <ng-container *ifScreenSize="screenType.Desktop">
      <ng-container *ngTemplateOutlet="cardBody; context: { $implicit: memberInfo }"></ng-container>
    </ng-container>
  </div>
</ng-container>
<ng-template #loadingRef>
  <fgbcl-loading></fgbcl-loading>
</ng-template>

<ng-template #cardBody let-memberInfo>
  <div class="member-card-body row">
    <ng-container *notInProductCode="productcode.SingleGameBuyer">
      <div class="img-holder col-7 pe-0">
        <img
          *ifScreenSize="screenType.Desktop"
          class="rounded"
          src="assets/images/member-card/{{ memberInfo.memberCard.ProductCode }}.png"
          draggable="false"
        />
        <img
          *ifScreenSize="screenType.Mobile"
          class="rounded"
          src="assets/images/mobile-member-card/{{ memberInfo.memberCard.ProductCode }}.png"
          draggable="false"
        />
      </div>
      <div class="ps-lg-0 values col-5">
        <div class="discount" [ngClass]="{ hidden: !memberInfo.memberCard.DiscountLevel }">
          <div class="d-flex justify-content-between align-items-baseline">
            <div class="title">
              {{ 'member.card.discount.label' | translate }}
            </div>
            <span class="material-icons text-secondary cursor-pointer visible" (click)="openModal()"> help </span>
          </div>
          <div class="value">{{ memberInfo.memberCard.DiscountLevel }}%</div>
        </div>

        <ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.ecash'">
          <div class="ecash">
            <div class="title">{{ 'member.card.grizzbucks.label' | translate }}</div>
            <div class="value">
              {{ memberInfo.ecash | FortressCurrencyPipe: '$' }}
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *inProductCode="productcode.SingleGameBuyer">
      <div class="img-holder pe-0">
        <div class="bg-secondary sgb-image-container rounded">
          <img class="rounded sgb-image" src="assets/images/login-image.svg" draggable="false" />
        </div>
      </div>
    </ng-container>

    <div class="row px-0 pt-4 mt-2 d-lg-none mx-0" *ngIf="!isMobileMenu">
      <div class="col-12">
        <a class="show-card-button font-praktika-bold border border-secondary" [routerLink]="'/rewards/wallet'">
          <span class="material-icons icon"> qr_code </span>
          {{ 'show.card.button' | translate }}
        </a>
      </div>
    </div>
  </div>
</ng-template>
