import { Component, OnInit } from '@angular/core';
import { ProductCode } from 'src/app/shared/utilities/product-code';

@Component({
  selector: 'fgb-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  productcode = ProductCode;
  constructor() {}

  ngOnInit() {}
}
