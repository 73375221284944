import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, LoggingService, config, tmAuthConfig } from '@fgb/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'fgb-tm-auth-callback',
  templateUrl: './tm-auth-callback.component.html',
  styleUrls: ['./tm-auth-callback.component.scss'],
})
export class TmAuthCallbackComponent implements OnInit {
  constructor(
    private cookie: CookieService,
    public logService: LoggingService,
    private router: Router,
    private http: HttpClient,
    private authService: AuthService,
    private tmConfig: tmAuthConfig
  ) {}

  ngOnInit() {
    let code = this.getParameter('code');
    let state = this.getParameter('state');
    if (code != '') {
      this.authCallbackRedirect(code, state);
    }
  }

  getParameter(name: string): string {
    const results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(window.location.href);
    if (!results) {
      return '';
    }
    return (results[1] as string) || '';
  }

  authCallbackRedirect(
    code: string,
    state: string,
    clubId: number = 1,
    redirectUrl: string = '/',
    registrationUrl: string = '/register/provideroauth',
    chooseRegUrl: string = '/login'
  ) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    let cookieState = this.cookie.get('tm-state');
    let stateSplit = state.split('_');
    let direction = 0;

    if (stateSplit.length > 1) {
      if (stateSplit[1] === 'O') {
        direction = 1;
      } else if (stateSplit[1] === 'R') {
        direction = 2;
      }
    }

    let callbackSubscription = this.http
      .post<any>(config.baseApiUrl + '/api/sso/oauth/2/' + code + '/' + clubId, null, { headers: headers })
      .subscribe(
        (data) => {
          if (state !== cookieState) {
            this.router.navigate(['/login']);
            this.logService.debug('invalid state');
          } else {
            if (direction == 0) {
              // login page
              if (data.tokens && data.tokens.AccessToken) {
                // set provider access and refresh token in local storage
                localStorage.setItem('TMRefreshToken', data.auth.RefreshToken.toString());
                localStorage.setItem('TMAccessToken', data.auth.AccessToken.toString());
                callbackSubscription.unsubscribe();
                this.authService.loginUsingToken(data.tokens.AccessToken, data.tokens.RefreshToken, redirectUrl);
              } else {
                // User doesn't exist within fortress, redirect to chooseRegUrl
                callbackSubscription.unsubscribe();
                this.router.navigate([chooseRegUrl]);
              }
            } else if (direction == 2) {
              // registration page
              callbackSubscription.unsubscribe();
              this.router.navigateByUrl(registrationUrl, { state: { AccountNumber: data.auth.AccountNumber } });
            } else {
              // to mordern account
              if (data.tokens && data.tokens.AccessToken) {
                callbackSubscription.unsubscribe();
                window.location.href = this.tmConfig.loginUri + '?accessToken=' + data.auth.AccessToken + '&redir=myevents';
              } else {
                callbackSubscription.unsubscribe();
                this.router.navigate(['/']);
                this.logService.debug('not valid token');
              }
            }
          }
        },
        (errorException) => {
          this.logService.debug(errorException);
          callbackSubscription.unsubscribe();
          this.router.navigate(['/login'], { queryParams: { errorCode: errorException.status } });
        }
      );
  }
}
